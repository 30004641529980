import { $axios } from '../config/axios'
import {
  CompetingGoods,
  ReqCompetingGoods,
  ResCompetingGoods,
  ResBatchCompetingGoods,
  CompetingGoodsDetail,
} from '@/model/competitiveAnalysis/competingGoodsData/competingGoods'
import { ResCompetitorAccount } from '@/model/competitiveAnalysis/peerData/competitorAccount'
class InfoApi {
  DetailList(data: ReqCompetingGoods): Promise<ResCompetingGoods> {
    return $axios.post('/api/compete/detailList', data)
  }
  //新增竞品信息
  AddProduct(data: CompetingGoods): Promise<CompetingGoods> {
    return $axios.post('/api/compete/addProduct', data)
  }
  //批量新增竞品信息
  BatchAddProduct(data: CompetingGoods[]): Promise<ResBatchCompetingGoods> {
    return $axios.post('/api/compete/batchAddProduct', data)
  }
  //批量修改竞品信息
  BatchUpdateProduct(data: CompetingGoods[]): Promise<ResBatchCompetingGoods> {
    return $axios.post('/api/compete/batchUpdateProduct', data)
  }
  //修改竞品信息
  UpdateProduct(data: CompetingGoods): Promise<CompetingGoods> {
    return $axios.post('/api/compete/updateProduct', data)
  }
  //展示已经填报竞品信息
  InfoList(data: ReqCompetingGoods): Promise<ResCompetitorAccount> {
    return $axios.post('/api/compete/list', data)
  }
  //展示未填报竞品信息
  NotReportedList(data: ReqCompetingGoods): Promise<ResCompetingGoods> {
    return $axios.post('/api/compete/notReportedList', data)
  }
  //删除竞品信息
  InfoDelete(data: unknown): Promise<ResCompetingGoods> {
    return $axios.post('/api/compete/delete', data)
  }
  //竞品信息详情展示
  GetlistDetail(data: unknown): Promise<CompetingGoodsDetail> {
    return $axios.post('/api/compete/listDetail', data)
  }
}
let infoApi
export default (() => {
  if (infoApi) return infoApi
  infoApi = new InfoApi()
  return infoApi
})()
