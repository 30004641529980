
import { computed, defineComponent, toRefs, reactive } from 'vue'
import detailLayout from '@/components/detailLayout/index.vue'
import infoApi from '@/axios/api/info'
import { useRoute, useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
import {
  CompetingGoodsDetailItem,
  CompetingGoodsDetail,
} from '@/model/competitiveAnalysis/competingGoodsData/competingGoods'
import Data from '@/model/common/Data'
export default defineComponent({
  components: {
    detailLayout,
  },
  setup() {
    const pageData = reactive({
      columns: [
        {
          title: '数量',
          dataIndex: 'quantity',
          key: 'quantity',
          width: 369,
          resizable: true,
        },
        {
          title: '单价（不含税）',
          dataIndex: 'priceWithoutTax',
          key: 'priceWithoutTax',
          width: 369,
          resizable: true,
        },
        {
          title: '交期',
          dataIndex: 'day',
          key: 'day',
          width: 369,
          resizable: true,
        },
      ],
      dataSource: [] as Data[],
      attributeTableList: [] as Data[],
      detail: {
        yhdProductInfoVO: {
          productModel: '', //怡合达物料规格
          modelCode: '', //物料编号
          productName: '', //物料名称
          dptName: '', //所属部门
          empName: '', //部门经理
        },
        competitorProductDetailAndBasicInfoVOList: [] as CompetingGoodsDetailItem[],
      } as CompetingGoodsDetail,
      tabKey: '',
      tabDetail: {} as CompetingGoodsDetailItem,
      imgList: [] as Data[],
    })
    let route = useRoute()
    let router = useRouter()
    if (route.query.modelCode) {
      let data = {
        modelCode: route.query.modelCode,
        productModel: route.query.productModel,
        productName: route.query.productName,
      }
      infoApi
        .GetlistDetail(data)
        .then((res) => {
          console.log(res)
          pageData.detail = res
          // tabChange(route.query.tabName as string)
        })
        .catch((err) => {
          message.error(err)
        })
    } else {
      router.push('/competitiveAnalysis/competingGoodsData')
    }
    const tabList = computed(() => {
      let data = pageData.detail?.competitorProductDetailAndBasicInfoVOList?.map((item) => {
        return {
          key: item.competitorProductBasicInfoVO.accompanyName,
          name: item.competitorProductBasicInfoVO.accompanyName,
        }
      })
      console.log(data, 'tabList')

      return data || []
    })
    const tabChange = (key: string) => {
      console.log('选择事件', key)
      let data = pageData.detail?.competitorProductDetailAndBasicInfoVOList?.find(
        (item) => item.competitorProductBasicInfoVO?.accompanyName === key
      ) as CompetingGoodsDetailItem
      pageData.tabDetail = data
      console.log(pageData.detail?.competitorProductDetailAndBasicInfoVOList)
      pageData.dataSource = JSON.parse(data.competitorProductDetailVO.discountTable as string) || []
      pageData.imgList = []
      if (data.competitorProductDetailVO.imageIdList) {
        pageData.imgList = data.competitorProductDetailVO.imageIdList.split(';').map((item) => {
          return {
            src: item,
          }
        })
      }
    }
    //查询价期
    const selectPriceDay = () => {
      let data = pageData.dataSource.find((item: Data) => {
        let min = +(item.quantity as string).split('~')[0]
        let max = +(item.quantity as string).split('~')[1]
        if (max) {
          return +selectDataForm.selectData >= min && +selectDataForm.selectData <= max
        } else {
          return +selectDataForm.selectData >= min
        }
      })
      selectDataForm.selectPriceWithoutTax = ''
      selectDataForm.selectDay = ''
      if (data) {
        selectDataForm.selectPriceWithoutTax = data.priceWithoutTax + ''
        selectDataForm.selectDay = data.day + ''
        message.success('查询成功')
      } else {
        message.warning('未查询到价期')
      }
    }
    const selectDataForm = reactive({
      selectData: '',
      selectPriceWithoutTax: '',
      selectDay: '',
    })
    return {
      ...toRefs(selectDataForm),
      ...toRefs(pageData),
      tabList,
      tabChange,
      selectPriceDay,
      img: require('@/assets/img/暂无数据.png'),
    }
  },
})
